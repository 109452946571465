import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'coffee',
  formId: 'UK%20general%20coffee',
  title: 'Coffee',
  subTitle: 'Love is in the air, and it smells like coffee.',
  theme: 'sunrise-coffee',
  icon: '/icons/coffee.svg',
  image: '/assets/images/sunrise/coffee_supermercat.png',
  sections: [
    {
      sectionId: 'hero',
      label: 'Coffee',
      title: 'Love is in the air, and it smells like coffee.',
      description:
        'Let’s face it - life is too short for bad coffee. We’ll make sure you find the fruity, the regular, the strong and the leight-weighter right here. We also have the perfect machine for your particular needs.',
      image: '/assets/images/sunrise/coffee_supermercat.png',
      icon: '/icons/coffee.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Everything you need for a good cup of joe',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/coffee/service_1.svg',
          title: 'Coffee beans',
          description:
            'We have beans for every taste and price point. Create all types of coffee from the regular Joe to the foamy cappucino.',
        },
        {
          icon: '/assets/images/services/coffee/service_3.svg',
          title: 'Coffee pods',
          description: '',
        },
        {
          icon: '/assets/images/services/coffee/service_2.svg',
          title: 'Coffee machines',
          description:
            'The kitchen alter and most crucial item at the office. We got it. We have coffee machines in all price levels and variations.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Coffee is just a few steps away',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/coffee/journey_1.svg',
          title: 'Tell us what you like',
          description: 'We have a vast variety of everything. Tell us what you like - machine or beans!',
        },
        {
          icon: '/assets/images/services/coffee/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal to serve your coffee cravings!',
        },
        {
          icon: '/assets/images/services/coffee/journey_3.svg',
          title: 'Accept!',
          description: 'That’s it! You’re now running on the best brew in town!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love coffee from Good Monday',
      description: '',
      image: '/assets/images/services/coffee/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-eye-outline',
          title: 'Easy overview',
          description: 'Keep track of the daily consumption and make sure you know exactly what your colleagues need.',
        },
        {
          icon: 'eva eva-sync-outline',
          title: 'Automatic refill of beans',
          description: 'You can easily add beans to your Good Monday consumables list.',
        },
        {
          icon: 'eva eva-edit-2-outline',
          title: 'Option to change beans at any time',
          description: 'Want to try a new style of bean? No problem. We have a ton of different variations.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that Good Monday serves around 5000 cups of coffee every single day?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
